import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';
 
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
 
  constructor(
    private router: Router,
    public auth: AuthenticationService) {}
 
  canActivate(): boolean {
    var state=this.auth.isAuthenticated()
    if ( !state  ) {
      this.router.navigate(["login"]);
      return false;
    }

    console.log("auth.isAuthenticated");
    return state;
  }
}
