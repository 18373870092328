import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./public/login/login.module').then( m => m.LoginPageModule)
  },
  { 
    path: 'egoera3',
    canActivate: [AuthGuard],
    loadChildren: () => import('./members/egoera3/egoera3.module').then( m => m.Egoera3PageModule)
  },
  { 
    path: 'list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./members/list/list.module').then( m => m.ListPageModule)
  },
  { 
    path: 'egoera3/:id_zaintzapekoak', 
    canActivate: [AuthGuard],
    loadChildren: () => import('./members/egoera3/egoera3.module').then( m => m.Egoera3PageModule)
  },
  {
    path: 'egoera3-modal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./members/egoera3-modal/egoera3-modal.module').then( m => m.Egoera3ModalPageModule)
  }






  /*,
  { 
    path: 'members', 
    canActivate: [AuthGuard],
    loadChildren: () => import('./members/member-routing.module').then( m => m.MemberRoutingModule)
  }*/
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
